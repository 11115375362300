import { Outlet } from "react-router-dom"
import NavMenu from "./NavMenu"
import Footer from "./Footer"
import "./Layout.css"

const Layout = () => {
    return (
      <div>
        <NavMenu />
        <br/><br/><br/>
        <div className="content">
          <Outlet />
        </div>
          <Footer/>
      </div>
    )
}

export default Layout