import axios from 'axios';

export const addLogger = (logs, username, authToken, type) => {

    var itemContent = JSON.stringify({
        "log": JSON.stringify({logs}),
        "username": username,
        "authToken": authToken,
        "logType": type
    });

    var configitemContent = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'logger/saveLog',
        headers: { 
            'Content-Type': 'application/json'
        },
        data : itemContent
    };

    axios(configitemContent).then(function (response) {
    
        if (response.status === 200) {
            // console.log('response', response);
        } 
    })
    .catch(function (error) {
        console.log(error);
    });
      
}
