import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './Login.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from '../../redux/slices/authSlice';
import baseURL from '../../utils/baseURL';
import { Oval } from 'react-loader-spinner'
import axios from 'axios';
import { errorMessage } from '../../utils/messages'
import { ToastContainer } from 'react-toastify';
import { addLogger } from '../../utils/logs';


import { useSelector } from 'react-redux';
import { selectCurrentToken, selectCurrentUser } from '../../redux/slices/authSlice';
// CP 06-June-23 - End

/// ///////////////////////////////////////////////////////////////////////
///
/// Class Name   : Login.js
///
/// <summary>       : Uses Storeganise USER api to get session bearer token and return a list of orders (most recent first).
///                   NOTE:
///
/// </summary>
///
/// Author           : Chris Porter
///
/// Revision History :
/// ----------------------------------------------------------------------
/// Author     Date      CR Ref #     Description
/// ----------------------------------------------------------------------
/// RS         Mar-23                 Created.
/// CP         02-Apr-24              Amended to incl
///
const Login = () => {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [emailValid, setEmailValid] = useState(true);
    const [inValid, setInValid] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordValid, setPasswordValid] = useState(true);
    const [authError, setAuthError] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    // CP 02-June-23 - Start
    const sid = '';
    const authToken = useSelector(selectCurrentToken);
    // CP 02-June-23 - End

    const dispatch = useDispatch()

    const formChangeHandle = (event) => {
        const formControls = event.target;

        if (formControls.name === 'email') {
            setEmail(formControls.value)

            if (formControls.value.length > 0) {
                setEmailValid(true);
            } else {
                setEmailValid(false);
            }

            if (inValid) {
                if (email.includes('@')) {
                    setInValid(false);
                }
            }
        }

        if (formControls.name === 'password') {
            setPassword(formControls.value)

            if (formControls.value.length > 0) {
                setPasswordValid(true);
            } else {
                setPasswordValid(false);
            }
        }

    }

    const submitLogin = (event) => {
        event.preventDefault();

        // const user = 'rokonsr@gmail.com';
        // const userData = {
        //   username: email,
        //   accessToken: '123456'
        // }

        // dispatch(setCredentials({ ...userData, user }))
        // let dt = new Date();
        // localStorage.setItem('tokenExpire', new Date(dt.setMinutes(dt.getMinutes() + 15)));

        // navigate('/collections');

        if (email.length === 0) {
            setEmailValid(false);
            return;
        }

        if (!email.includes('@')) {
            setInValid(true);
            return;
        }

        if (password.length === 0) {
            setPasswordValid(false);
            return;
        }

        const authKey = window.btoa(email + ':' + password);

        setShowLoader(true);

        // CP 02-Apr-24 - Start
        // Changed from ?include=valetOrders to ?include=* so we can get the users marketplaceid needed in the SetCollectionDate.js page to get the marketplace excluded dates for datepicker setup 
        // This returns the user info and a list of non-completed orders. The most recent appears to be first in the list. STEP information is NOT returned here.
        var config = {
            method: 'POST',
            maxBodyLength: Infinity,
            url: baseURL() + "/v1/auth/token?include=*",
            headers: {
                'Authorization': 'Basic ' + authKey
            }
        };
        // CP 02-Apr-24 - End

        // Request Log
        addLogger(config, email, "", "RequestAuth_loginjs");

        axios(config).then(function (response) {

            // Response Log
            addLogger(response, email, "", "ResponseAuth_loginjs");

            if (response.status === 200) {
                dispatch(setCredentials({ ...response.data, email }))
                let dt = new Date();
                localStorage.setItem('tokenExpire', new Date(dt.setMinutes(dt.getMinutes() + 15)));
                setShowLoader(false);

                // CP 02-June-23 - Start
                // NOTE: Axios decodes response into json objects
                const resultx = response.data.user.valetOrders.length;

                if (resultx !== 0) {
                    // Get the order ID and sid
                    var valetOrderId = response.data.user.valetOrders[0].id // needed to get order steps

                    var valetOrderSID = response.data.user.valetOrders[0].sid // needed to move order step

                    // Get the order with step info
                    var configx = {
                        method: 'GET',
                        maxBodyLength: Infinity,
                        url: baseURL() + "/v1/valet/orders/" + valetOrderId + "?include=job",
                        headers: {
                            'Authorization': 'Bearer ' + authToken
                        }
                    }

                    // Request Log
                    addLogger(configx, email, "", "RequestOrderStepInfo_loginjs");
                    axios(configx).then(function (response) {

                        // Response Log
                        addLogger(response, email, authToken, "ResponseOrderStepInfo_loginjs");

                        if (response.status === 200) {

                            // Got 200 back, get the current step
                            var currStep = response.data.job.step;
                            addLogger(response.data.job.step, email, authToken, "ResponseOJobStep_loginjs");

                            if (currStep === "order_start") {
                                // Complete all the steps up to the collect step
                                // This is a call to THIS site and hits the ORDERController.cs. SetupProxy.js will need changing if new controller is added.
                                var configo = {
                                    method: 'GET',
                                    maxBodyLength: Infinity,
                                    url: "order/UpdateOrderStep?orderSID=" + valetOrderSID
                                };

                                // Request Log
                                addLogger(configo, email, "", "RequestUpdateOrdetStep_loginjs");
                                axios(configo).then(function (response) {
                                    // Response Log
                                    addLogger(response, email, authToken, "ResponseUpdateOrderStep_loginjs");

                                    if (response.status === 200) {

                                    } else {
                                        errorMessage();
                                    }
                                });


                            }
                        } else {
                            errorMessage();
                        }
                    });
                }


                // CP 02-June-23 - End

                // Collections page
                navigate('/collections');

            } else {
                setShowLoader(false);
                errorMessage();
            }
        })
            .catch(function (error) {
                // Exception Log
                addLogger(error, email, "", "Exception");
                if (error.response.status === 401) {
                    setShowLoader(false);
                    let errorMessage = error.response.data.error.message;
                    setAuthError(errorMessage);
                } else {
                    setShowLoader(false);
                    errorMessage();
                }
            });
    }


    return (
        <div className="app-1ptv28q">
            <h1 className="my-5 app-bz3gyv title-h1">
                <em>Login</em> to your account
            </h1>
            <div className="app-tbe3dj">
                <form>
                    <div className="d-flex flex-column">
                        <div className='field-gap'>
                            <div className="form-floating">
                                <input id="email" name="email" required="" placeholder=" " maxLength='100' className="form-control" onChange={formChangeHandle} autoComplete='off' />
                                <label htmlFor="email">Email address *</label>
                            </div>
                            <small className="text-muted"></small>
                            {!emailValid && <span className='text-danger'>Username is required</span>}
                            {inValid && <span className='text-danger'>Invalid email address</span>}
                        </div>
                        <div className='field-gap'>
                            <div className="form-floating">
                                <input id="password" name="password" required="" maxLength='100' type="password" placeholder=" " className="form-control" onChange={formChangeHandle} autoComplete='off' />
                                <label htmlFor="password">Password *</label>
                            </div>
                            <small className="text-muted"></small>
                            {!passwordValid && <span className='text-danger'>Password is required</span>}
                        </div>
                        {
                            authError !== '' ? <div className='alert alert-danger' role='alert'>
                                <span className='text-danger'>{authError}</span>
                            </div> : ''
                        }
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Oval
                            height={40}
                            width={40}
                            color="#C227B9"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={showLoader}
                            ariaLabel='oval-loading'
                            secondaryColor="#ce54c7"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                        />
                    </div>
                    <button className="login-button mt-3 mb-2" type="button" onClick={submitLogin}><span>Sign in</span></button>
                    <div className="text-center">
                        <a className="fs-7 text-primary btn btn-link btn-sm text-start" target='_blank' rel='noreferrer' href="https://spaceways.storeganise.com/account/forgot-password"><span>Forgot password?</span></a>

                    </div>
                </form>
                <div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Login