import { createSlice } from "@reduxjs/toolkit"

const itemSlice = createSlice({
    name: 'items',
    initialState: { items: null },
    reducers: {
        setItems: (state, action) => {
            state.items = action.payload;
        }
    },
})

export const { setItems } = itemSlice.actions

export default itemSlice.reducer

export const selectCurrentItems = (state) => state.items.items