import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './components/layouts/Layout';
import Collections from './components/pages/Collections';
import './custom.css';
import Login from './components/auth/Login';
import RequireAuth from './components/auth/RequireAuth';
import Confirmation from './components/pages/Confirmation';
import SetCollectionDate from './components/pages/SetCollectionDate';

const App = () => {
  // const displayName = App.name;
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route index element={<Login />} />
        <Route path="confirmation" element={<Confirmation />} />
        {/* protected routes */}
        <Route element={<RequireAuth />}>
          <Route path="collections" element={<Collections />} />
          <Route path="collectionDate" element={<SetCollectionDate />} />
        </Route>

      </Route>
    </Routes>
  )
}

export default App;
