import React from 'react'
import './Footer.css';

const footer = () => {
  return (
    <footer className="fixed-bottom">
      <div className='container'>
        
          <div className='row'>
            <div className='col-8'>
                © Spaceways.co.uk (2024)
            </div>
            <div className='col-4 text-end'>
              <a href="tel:0800 1126 242">0800 1126 242</a>
            </div>
          </div>
      </div>
    </footer>
  )
}

export default footer