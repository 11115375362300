import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useDispatch } from 'react-redux'
import { setCredentials, logOut, selectCurrentToken } from '../../redux/slices/authSlice';
import { useSelector } from "react-redux"
import { Collapse, NavLink } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';

const NavMenu = () => {
  const dispatch = useDispatch()

  const token = useSelector(selectCurrentToken)

  const logoutHandler = () => {
    dispatch(setCredentials(logOut()))
    localStorage.clear();
  }

  const [toggle, setToggle] = useState(false);

  const toggleHandler = () => {
    let isOpen = toggle ? false : true;
    setToggle(isOpen);
  }

//<i className="fal fa-bars"></i>
  return (
    <header>
      <nav className="navbar navbar-expand-md fixed-top app-khjyy2">
        <div className="container-xxl">
          <a className="navbar-brand" href="/"><img src="https://storeganise.s3.amazonaws.com/62e3d36dea9e450004e138de/uploads/015656d1f92aae1eee6f0c0f288e25ec.png" alt="Spaceways" className="app-3aekjs"/></a>
          <button className="navbar-toggler collapsed text-primary" type="button" onClick={toggleHandler}><FontAwesomeIcon icon={toggle ? faXmark : faBars}/></button>
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={toggle} navbar> 
            <ul className="navbar-nav me-auto mb-2 mb-md-0 w-100 justify-content-end gap-1">
              <li className="nav-item ms-md-3">
                { !token ? <NavLink tag={Link} className="brand-text-color" to="/">Log in</NavLink> : <NavLink tag={Link} className="brand-text-color" to="/" onClick={logoutHandler}>Sign out</NavLink> }
              </li>
              <li className="nav-item ms-md-1">
                <NavLink tag={Link} className="text-dark" to="/collections">Collection</NavLink>
              </li>
            </ul>
          </Collapse>
        </div>
      </nav>
    </header>
  );
}

export default NavMenu;
