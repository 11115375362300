import React, { useState, useEffect } from 'react';
import './SetCollectionDate.css';
import { useDispatch, useSelector } from "react-redux";
import { setItems, selectCurrentItems } from '../../redux/slices/itemSlice';
import { selectCurrentUser, selectCurrentToken } from '../../redux/slices/authSlice';
import baseURL from '../../utils/baseURL';
import { useNavigate } from 'react-router-dom';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from 'axios';
import { errorMessage } from '../../utils/messages'
import { ToastContainer } from 'react-toastify';
import { addLogger } from '../../utils/logs';

/// ///////////////////////////////////////////////////////////////////////
///
/// Class Name   : SetCollectionDate.js
///
/// <summary>       : Updates the Storeganise order with collection date, address and contact details.
///
/// </summary>
///
/// Author           : Chris Porter
///
/// Revision History :
/// ----------------------------------------------------------------------
/// Author     Date      CR Ref #     Description
/// ----------------------------------------------------------------------
/// RS         Mar-23                 Created.
/// CP         26-Mar-24              Amended for 2024.
///                                    (1) The collection date is now synced with the customers associated marketplace calendar.
///                                    (2) Editing of orders not allowed within 24 hours of the service date. Customer must ring in.

const SetCollectionDate = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const authToken = useSelector(selectCurrentToken);
	const [email, setEmail] = useState('');
	const [emailValid, setEmailValid] = useState(true);
	const [inValid, setInValid] = useState(true);
	const [confirmDate, setConfirmDate] = useState(new Date());
	const [collectionAddress, setCollectionAddress] = useState('');
	const [collectionAddressValid, setCollectionAddressValid] = useState(true);
	const [minDate, setMinDate] = useState('');
	const [maxDate, setMaxDate] = useState('');
	const [valetOrder, setValetOrder] = useState('');

	const userDetail = useSelector(selectCurrentUser);
	const items = useSelector(selectCurrentItems);

	// CP 02-Apr-24 - Start
	// Array of dates to exclude for date picker
	const [disabledDates, setDates] = useState([
		new Date(),
	]);

	useEffect(() => {

		setEmail(userDetail.email);

		let newDate = new Date()
		let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1);
		let currentDate = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();

		const minDateCreation = newDate.getFullYear() + '-' + month + '-' + ((newDate.getDate() + 3) < 10 ? '0' + (newDate.getDate() + 3) : (newDate.getDate() + 3));
		setMinDate(minDateCreation);

		let month1 = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 3) : (newDate.getMonth())
		const maxDateCreation = newDate.getFullYear() + '-' + month1 + '-' + currentDate;
		setMaxDate(maxDateCreation);



		// CP 27-Mar-24 - Start
		// We need to get the users associated marketplace excluded dates from the Storeganise settings - MUST BE ADMIN API, uses the SettingsController.
		var stoSettings = {
			method: 'GET',
			maxBodyLength: Infinity,
			url: "settings/GetMarketplaceExcludedDates?MarketplaceID=" + userDetail.settings.marketId // settings controller
		};

		// Request log
		addLogger(stoSettings, "", "", "GetStoreganiseSettings_SetCollectsDatejs");

		axios(stoSettings).then(function (response) {
			// Response Log
			addLogger(response, "", authToken, "GetStoreganiseSettings_SetCollectsDatejs");

			if (response.status === 200) {
				// Push the returned dates into the disabledDates array (i.e. grey-out any date in the returned list)
				if (response.data.length != 0) {
					const excludeDatesStr = response.data;

					// Iterate through string array, convert each to js date and add to disabled dates (date array)
					var i = 0;
					for (i = 0; i < excludeDatesStr.length; i++) {

						const newDate = new Date(excludeDatesStr[i])
						setDates(prevDates => [...prevDates, newDate]); // Add the new date to the existing array using the spread operator
					}
				}
			}
			else {
				errorMessage();
			}
		});
		// CP 27-Mar-24 - End



		var config = {
			method: 'get',
			maxBodyLength: Infinity,
			url: baseURL() + "/v1/valet/orders",
			headers: {
				'Authorization': 'Bearer ' + authToken
			}
		};

		// Look at the most recent order
		axios(config).then(function (response) {

			if (response.status === 200) {
				setValetOrder(response.data);
				if (response.data.length > 0) {
					setConfirmDate(new Date(response.data[0].collectDate));
					setCollectionAddress(response.data[0].address);
				} else {
					setConfirmDate(new Date(minDateCreation));
					setCollectionAddress(userDetail.address);
				}



			} else {
				errorMessage();
			}
		})
			.catch(function (error) {
				errorMessage();
			});

	}, [userDetail.email, userDetail.address, authToken]);




	// CP 02-Apr-24 - Start
	//const handleDateChange = date => {
	//	setConfirmDate(date);
	//};
	// CP 02-Apr-24 - End

	// let newDate = new Date()
	// let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1)
	// let currentDate = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
	// const today = newDate.getFullYear() + '-' + month + '-' + currentDate;

	const formChangeHandle = (event) => {

		const formControls = event.target;

		if (formControls.name === 'emailAddress') {
			setEmail(formControls.value)

			if (formControls.value.length > 0) {
				setEmailValid(true);

			} else {
				setEmailValid(false);
			}

		}

		// if (formControls.name === 'confirmDate') {
		//   setConfirmDate(formControls.value)

		//   if (formControls.value.length > 0) {
		//     setConfirmDateValid(true);
		//   } else {
		//     setConfirmDateValid(false);
		//   }
		// }

		if (formControls.name === 'collectionAddress') {
			setCollectionAddress(formControls.value)

			if (formControls.value.length > 0) {
				setCollectionAddressValid(true);
			} else {
				setCollectionAddressValid(false);
			}
		}

	}

	const submitConfirmDate = (event) => {
		event.preventDefault();

		if (!email.includes('@')) {
			setInValid(false);
			return;
		}

		let month = (confirmDate.getMonth() + 1) < 10 ? '0' + (confirmDate.getMonth() + 1) : (confirmDate.getMonth() + 1);
		let currentDate = confirmDate.getDate() < 10 ? '0' + confirmDate.getDate() : confirmDate.getDate();
		const confirmationDate = confirmDate.getFullYear() + '-' + month + '-' + currentDate;

		const data = [...items];

		data.forEach(item => {
			if (!item.id) {
				// CP 11-Apr-23 - Start
				//var firstOrderID = "";

				//// throws an error is customer has no current order as valetorder[0] doesnt exist
				//if (valetOrder.length === 0) {
				//    firstOrderID = "";
				//}
				//else {
				//    firstOrderID = valetOrder[0].id;
				//}
				// CP 11-Apr-23 - End

				var itemContent = JSON.stringify({
					"sid": item.sid,
					"valetOrderId": valetOrder[0].id,
					"ownerId": userDetail.id,
					"opsTitle": item.opsTitle
				});

				var configitemContent = {
					method: 'post',
					maxBodyLength: Infinity,
					url: 'item/saveItem',
					headers: {
						'Content-Type': 'application/json'
					},
					data: itemContent
				};

				// Request Log
				addLogger(configitemContent, userDetail.email, authToken, "Request");

				axios(configitemContent).then(function (response) {

					// Response Log
					addLogger(response, userDetail.email, authToken, "Response");

					if (response.status !== 200) {
						errorMessage();
					}

				})
					.catch(function (error) {
						// Exception Log
						addLogger(error, userDetail.email, authToken, "Exception");
						errorMessage();
					});

			}
		});

		if (valetOrder.length > 0) {

			var setDate = {
				"collectDate": confirmationDate,
				"address": collectionAddress
			};

			// Update the order. If this fails it is likely because the timeslot is BLANK in the users associated marketplace!
			var configValetOrder = {
				method: 'PUT',
				data: setDate,
				maxBodyLength: Infinity,
				url: baseURL() + "/v1/valet/orders/" + valetOrder[0].id,
				headers: {
					'Authorization': 'Bearer ' + authToken
				}
			};

			// Request Log
			addLogger(configValetOrder, userDetail.email, authToken, "Request");

			axios(configValetOrder).then(function (response) {

				// Response Log
				addLogger(response, userDetail.email, authToken, "Response");

				if (response.status === 200) {
					//dispatch(setItems(null))
					navigate('/confirmation');
				} else {
					errorMessage();
				}
			})
				.catch(function (error) {
					// Exception Log
					addLogger(error, userDetail.email, authToken, "Exception");
					errorMessage();
				});

		} else {
			errorMessage();
		}

	}

	return (
		<div className='container'>

			<form>
				<div className='confirm-box'>
					<h3 className="text-primary text-center"><u>Confirm </u><span className="text-muted"><u>your collection</u></span></h3>

					<div className='row'>
						<b className="text-muted">Date</b>
					</div>
					<div className='row'>
						<DatePicker selected={confirmDate} onChange={(date) => setConfirmDate(date)} className='date-field'
							minDate={new Date(minDate)} maxDate={new Date(maxDate)} dateFormat="dd/MM/yyyy"
							placeholderText='Please select confirm date' excludeDates={disabledDates} />
					</div>

					<div className='row'>
						<b className="text-muted">Email Address</b>
					</div>
					<div className='row'>
						<input type='email' className='form-control' name='emailAddress' placeholder='Email Address' maxLength={100} readOnly={true}
							defaultValue={userDetail.email} onChange={formChangeHandle} autoComplete='off' />
						{!emailValid && <span className='text-danger'>Email is required</span>}
						{!inValid && <span className='text-danger'>Invalid email address</span>}
					</div>

		
					<div className='row'>
						<b className="text-muted">Address</b>
					</div>
					<div className='row'>
						<textarea type='text' className='form-control' name='collectionAddress' placeholder='Collection Address' defaultValue={collectionAddress}
							onChange={formChangeHandle} autoComplete='off'></textarea>
						{!collectionAddressValid && <span className='text-danger'>Collection address is required</span>}
					</div>

					<br />
					<div className='row'>
						<button type='button' className='confirm-button' onClick={submitConfirmDate}>CONFIRM</button>
					</div>
				</div>
			</form>
			<ToastContainer />
		</div>
	)
}

export default SetCollectionDate;