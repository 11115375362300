import { configureStore } from "@reduxjs/toolkit"
import authReducer from './slices/authSlice'
import itemReducer from './slices/itemSlice'
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist'
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, authReducer);
const itemPersistedReducer = persistReducer(persistConfig, itemReducer);

export const store = configureStore({
    reducer: {
        auth: persistedReducer,
        items: itemPersistedReducer
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
})

const persistor = persistStore(store);
export {persistor}
