import React, { useState, useEffect } from 'react';
import './Collections.css';
import camera from '../../images/camera.png';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentToken, selectCurrentUser } from '../../redux/slices/authSlice';
import { setItems } from '../../redux/slices/itemSlice';
import baseURL from '../../utils/baseURL';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import axios from 'axios';
import { errorMessage } from '../../utils/messages'
import { ToastContainer } from 'react-toastify';
import { addLogger } from '../../utils/logs';


//import CustomModal, {customModal } from './CustomModal';

/// ///////////////////////////////////////////////////////////////////////
///
/// Class Name   : Collections.js
///
/// <summary>       : Updates the Storeganise order step to COMPLETE
///
/// </summary>
///
/// Author           : Chris Porter
///
/// Revision History :
/// ----------------------------------------------------------------------
/// Author     Date      CR Ref #     Description
/// ----------------------------------------------------------------------
/// RS         Mar-23                 Created.
/// CP         26-Mar-24              Amended for 2024.
///                                    (1) Now we have a number of different item types to checkin.So we use the barcode prefix to identify
///                                     the Storeganise product code to ensure it is added to the Storeganise order as the correct type.
///                                     NOTE: The Spaceways (Wordpress) website also has an option for 'Own boxes' which we must also check to ensure we create the barcodes
///                                        as 'own boxes' equivalent product code to the standard supplied box product code.
///                                     The TR/Storeganise product code mapping is stored in the .ENV environment table as JSON object in 'REACT_APP_ProdMapping'.
///                                    (2) In 2023 we were oversupplying boxes (as only one box type supplied in packs of 5). This year we are not.
///                                      In last years scenario customer could effectively get any oversupplied boxes collected by returning to this website where it
///                                         would detect that they have no current order and automatically create one. However the creation of the new order was not
//                                          visible to the customer and they may not actually add any items for collection resulting in an 'empty' order for OPEX to sort out.
///                                      In this years scenario, we still detect the customer has no order but this time, inform them they dont have an order currently and ask
///                                         if they would like to create one. This could happen if all the barcodes/boxes supplied to the customer have not yet been collected.
///                                    (3) The collection date is now synced with the customer associated marketplace calendar.
///                                    (4) Editing of orders not allowed within 24 hours of the service date. Customer must ring in.
///                                    (5) Minor visual changes.
///                                    (6) Event logging back to SQL disabled as; (1) Website keeps a local file based log, (2) We are going to host externally.
///                                    (7) Checks for duplicate barcode being entered
///                                    (8) Image now a required field
/// CP        05-Jun-24                Amended to allow editing of order if scheduled for tomorrow UPTO midnight. This is likely to cause operational issues though
///                                     and checkerboard will detect this and email opex.
const Collections = () => {

	const userDetail = useSelector(selectCurrentUser);
	const authToken = useSelector(selectCurrentToken);
	const dispatch = useDispatch();

	const [valetOrder, setValetOrder] = useState('');

	const navigate = useNavigate();

	const hiddenFileInput = React.useRef(null);
	const [photoIndex, setPhotoIndex] = useState('')

	// CP 26-Mar-24 - Start
	// FORM FIELDS - The TYPE here will be overwritten with the mapped type of the barcode they have entered.
	// Image now mandatory, so errImage and imageInvalid added.
	const [formFields, setFormFields] = useState([{
		id: '', type: 'box', state: 'transit-storage', valetOrderId: '', ownerId: '', location: '', typeImage: '',
		sid: '', opsTitle: '', errBarcode: '', errDescription: '', errImage: '', barcodeInvalid: '', descriptionInvalid: '', imageInvalid: ''
	}]);
	// CP 26-Mar-24 - End

	const [itemAdd, setItemAdd] = useState(false);

	let newDate = new Date()
	let month = (newDate.getMonth() + 1) < 10 ? '0' + (newDate.getMonth() + 1) : (newDate.getMonth() + 1);
	let currentDate = newDate.getDate() < 10 ? '0' + newDate.getDate() : newDate.getDate();
	const today = newDate.getFullYear() + '-' + month + '-' + currentDate;
	const collectionDate = newDate.getFullYear() + '-' + month + '-' + ((newDate.getDate() + 3) < 10 ? '0' + (newDate.getDate() + 3) : (newDate.getDate() + 3));

	// CP 26-Mar-24 - Start
	// Load the Total Recall/Storeganise product mappings from the environment file '.env' so we can check what type of product the barcode entered is associated to.
	const prodmapping = process.env.REACT_APP_ProdMapping;

	// Convert to json oject so we can search it
	let prods = JSON.parse(prodmapping);

	const marketPlaceId = userDetail.settings.marketId;
	// CP 26-Mar-24 - End

	// CP 26-Mar-24 - The TYPE here will be overwritten with the mapped type of the barcode they have entered.
	const emptyForm = {
		id: '',
		type: 'box',
		state: 'transit-storage',
		valetOrderId: '',
		ownerId: '',
		location: '',
		typeImage: '',
		sid: '',
		opsTitle: '',
		errBarcode: '',
		errDescription: '',
		errImage: '',
		barcodeInvalid: '',
		descriptionInvalid: '',
		imageInvalid: ''
	};

	useEffect(() => {

		// CP 26-Mar-24 - Start
		// Replaced hard-coded area and timeslot with environment variables.

		var configOrder = {
			method: 'get',
			maxBodyLength: Infinity,
			url: baseURL() + "/v1/valet/orders",
			headers: {
				'Authorization': 'Bearer ' + authToken
			}
		};

		axios(configOrder).then(function (response) {

			if (response.status === 200) {
				if (response.data.length > 0) {
					// Get the first order in the order list and then get the items for it (if present)
					setValetOrder(response.data[0]);

					// CP 02-Apr-24 - Start
					// Check to see if the orders CURRENT collection date is within the next 24 hours. If so, we dont allow editing, pop-up a message, logout & return
					//  to login page.
					const currentDate = new Date();
					const futureDate = new Date(currentDate.getTime() + (24 * 60 * 60 * 1000)); // Add 24 hours in milliseconds to the current date/time

					const colDate = new Date(response.data[0].collectDate); // Has no time part

					// CP 05-Jun-24 - Start
					// We now allow edit upto the collection date

					// Strip time off the current date 
					var currentDateNoTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
					// Strip time off the collection date 
					var colDateNoTime = new Date(colDate.getFullYear(), colDate.getMonth(), colDate.getDate());

					if (colDateNoTime.getTime() === currentDateNoTime.getTime()) {

						// Trying to edit an order ON the collection date, dont allow any changes, customer must ring in.
						confirmAlert({
							title: 'Collection scheduled for TODAY',
							message: 'Your current collection date is TODAY and can no longer be amended online. Please contact Spaceways customer services on 0800 1126 242.',
							buttons: [
								{
									label: 'Return to login',
									className: 'btn btn-primary cancel-button',
									onClick: () => {
										localStorage.clear();
										navigate('/');
										window.location.reload();
									}
								}
							],
							closeOnClickOutside: false, // this makes it modal - see https://www.npmjs.com/package/react-confirm-alert
							onClickOutside: () => navigate('/')
						});
					}
					// CP 02-Apr-24 - End
					// CP 05-Jun-24 - End

					// Get the ITEMS for this order
					var configItems = {
						method: 'get',
						maxBodyLength: Infinity,
						url: "item/getItems?valetOrderId=" + response.data[0].id
					};

					axios(configItems).then(function (response) {

						if (response.status === 200) {
							// Order exists, load it         
							const result = response.data;

							if (result.length > 0) {
								result.forEach((item) => {
									item.typeImage = item.opsImages?.length > 0 ? item.opsImages[item.opsImages.length - 1].url : "";
									item.sid = item.sid.toUpperCase();

									item.descriptionInvalid = "";
									item.barcodeInvalid = "";
									item.imageInvalid = "";

									item.errBarcode = "";
									item.errDescription = "";
									item.errImage = "";
								})

								setFormFields(result);

							} else {
								setFormFields([...formFields]);
							}
						} else {
							errorMessage();
						}
					})
						.catch(function (error) {
							// errorMessage();
						});
				} else {
					// Order DOESNT EXIST

					// CP 26-Mar-24 - Start
					// Ask user if they want to create an order - note currently is async, shows message then falls through to subsequent code immediately after
					confirmAlert({
						title: 'Order status',
						message: 'You dont currently have a scheduled collection order, please contact Spaceways customer services on 0800 1126 242.',
						buttons: [
							{
								label: 'Return to login',
								className: 'btn btn-primary cancel-button',
								onClick: () => {
									localStorage.clear();
									navigate('/');
									window.location.reload();
								}

							}
						],
						closeOnClickOutside: false, // this makes it modal 
					});

					// CP 26-Mar-24 - End


					//// CP 26-Mar-24 - Start
					//// Replaced hard-coded area and timeslot with environment variables.
					//if (newOrder === true) {
					//	var order = JSON.stringify({
					//		"boxCounts": {
					//			"box": 1
					//		},
					//		"bulkyCounts": {},
					//		"productCounts": {},
					//		"address": userDetail.address,
					//		"phone": userDetail.phone,
					//		"area": process.env.REACT_APP_Area, // Take from .ENV environment variables file
					//		"date": today,
					//		"timeslot": process.env.REACT_APP_Timeslot, // Take from .ENV environment variables file
					//		"collect": "later",
					//		"collectDate": collectionDate,
					//		"collectTimeslot": process.env.REACT_APP_Timeslot // Take from .ENV environment variables file
					//	});

					//	// CP 26-Mar-24 - End

					//	// create new order
					//	var config = {
					//		method: 'post',
					//		maxBodyLength: Infinity,
					//		url: baseURL() + '/v1/valet/orders/',
					//		headers: {
					//			'Authorization': 'Bearer ' + authToken,
					//			'Content-Type': 'application/json'
					//		},
					//		data: order
					//	};

					//	// Request log 
					//	addLogger(config, "", "", "CreateNewOrder_collectjs");

					//	axios(config).then(function (response) {

					//		// Response log 
					//		addLogger(response, "", "", "CreateNewOrder_collectjs");

					//		if (response.status === 200) {
					//			setValetOrder(response.data);

					//			// CP 07-June-23 - Start
					//			// Now set the order step to collect
					//			// Complete all the steps up to the collect step
					//			// This is a call to THIS site and hits the ORDERController.cs.

					//			var valetOrderSID = response.data.sid; // needed to move order step

					//			var configo = {
					//				method: 'GET',
					//				maxBodyLength: Infinity,
					//				url: "order/UpdateOrderStep?orderSID=" + valetOrderSID
					//			};

					//			// Request log
					//			addLogger(configo, "", "", "RequestUpdateOrderStep_collectjs");

					//			axios(configo).then(function (response) {
					//				// Response Log
					//				addLogger(response, "", authToken, "ResponseUpdateOrderStep_collectjs");

					//				if (response.status === 200) {

					//				} else {
					//					errorMessage();
					//				}
					//			});
					//			// CP 07-June-23 - End
					//		} else {
					//			errorMessage();
					//		}
					//	})
					//		.catch(function (error) {
					//			errorMessage();
					//		});
					//} // new order
				}
			} else {
				setFormFields([...formFields]);
			}
		})
			.catch(function (error) {
				errorMessage();
			});

	}, [authToken])

	const addFormHandler = (event, index) => {
		// Key press event for Barcode or description

		let data = [...formFields];
		let name = event.target.name;
		let value = event.target.value;

		data[index].barcodeInvalid = "";
		data[index].errBarcode = "";
		data[index].imageInvalid = "";
		data[index].errImage = "";

		data[index][event.target.name] = event.target.value.toUpperCase();
		setFormFields(data);

		// Check the barcode
		if (name === 'sid') {
			var regexLetter1 = new RegExp("^[A-Za-z]$"); //First digit is alpha, then there are exactly 6 numbers
			const fs = value.substring(0, 1);
			const firstSegment = fs.toUpperCase();
			const secondSegment = value.substring(1, 7);

			var barcodePrefixCheck = prods.filter(pro => pro.trCode.includes(firstSegment));
			// CP 26-Mar-25 - Start
			// First character is a letter, but is it a valid one?
			// Check to see if the first alpha part of the barcode is a mapped one
			if (barcodePrefixCheck.length === 0) {

				data[index].barcodeInvalid = 'invalid';
				data[index].errBarcode = 'Please enter full 7 digit barcode';
				data[index][event.target.name] = value;

				setFormFields(data);
			}
			// CP 26-Mar-25 - End

			// CP 08-Apr-24 - Start
			// If all 7 characters now present, check to see the barcode hasnt been duplicated
			// Iterate through all barcodes to see if the value exists in any other
			if (value.length === 7) {
				for (let indexO = 0; indexO < data.length; indexO++) {
					const elementToCheckOuter = data[indexO];

					// Does this value exist in any other ?
					for (let indexI = 0; indexI < data.length; indexI++) {
						const elementToCheckInner = data[indexI];

						if (indexI !== indexO) {
							if (elementToCheckOuter.sid === elementToCheckInner.sid) {
								// Highlight the duplicates
								data[indexO].barcodeInvalid = 'invalid';
								data[indexO].errBarcode = 'This barcode has already been entered!';
							}
						}
						else {
							// Clear down error flags on other items
							data[indexO].barcodeInvalid = '';
							data[indexO].errBarcode = '';
						}
					}
				}
			}
			// CP 08-Apr-24 - End

			// Regex test - is first character a LETTER?
			if (!regexLetter1.test(firstSegment)) {
				data[index].barcodeInvalid = 'invalid';
				data[index].errBarcode = 'Please enter correct barcode';

				if (value !== "") {
					setFormFields(data);
				}
				else {
					data[index][event.target.name] = value;
					setFormFields(data);
				}

			} // end 1st digit checks
			else if (!isNumber(secondSegment) && secondSegment !== "") {
				data[index].barcodeInvalid = 'invalid';
				data[index].errBarcode = 'Please enter correct barcode';
				setFormFields(data);
			}

			// Check the description
		} else if (name === 'opsTitle') {
			if (name === '') {
				data[index].descriptionInvalid = 'invalid';
				data[index].errDescription = 'Please enter the item description';
			}
			else {
				data[index].descriptionInvalid = "";
				data[index].errDescription = "";

				data[index][event.target.name] = event.target.value;
				setFormFields(data);
			}
		}
	}

	const addMore = () => {
		if (formFields.length < 50) {
			setFormFields([...formFields, emptyForm]);
		} else {
			setItemAdd(true);
		}
	}

	const isNumber = (n) => { return /^-?[\d.]+(?:e-?\d+)?$/.test(n); }

	/// ///////////////////////////////////////////////////////////////////////
	///
	/// Function Name   : submit
	///
	/// <summary>       : Run when CONFIRM button clicked
	///
	/// </summary>
	///
	/// Author           : Chris Porter
	///
	/// Revision History :
	/// ----------------------------------------------------------------------
	/// Author     Date      CR Ref #     Description
	/// ----------------------------------------------------------------------
	/// RS         Mar-23                 Created.
	/// CP         09-Apr-24              Now checks that an image has been uploaded as otherwise the item isnt added to the order!
	///
	///
	const submit = () => {
		// Run when CONFIRM button clicked

		let data = [...formFields];

		// CP 07-June-23 - Start

		// First reset all flags and messages
		for (let indexE = 0; indexE < data.length; indexE++) {
			const element = data[indexE];

			data[indexE].descriptionInvalid = "";
			data[indexE].errDescription = "";
			data[indexE].barcodeInvalid = "";
			data[indexE].errBarcode = "";
			data[indexE].errImage = "";
			data[indexE].imageInvalid = "";
		}

		// CP 26-Mar-24 - Start
		// Barcode length now changed to 7 digits.
		for (let index = 0; index < data.length; index++) {
			const element = data[index];
			if (element.sid === '') {
				data[index].barcodeInvalid = 'invalid';
				data[index].errBarcode = 'Please enter full 7 digit barcode';
			} else if (element.sid.length !== 7) {
				if (element.sid.length !== 8) {
					data[index].barcodeInvalid = 'invalid';
					data[index].errBarcode = 'Please enter full 7 digit barcode';
				}
			} else {
				var regexLetter2 = new RegExp("^[A-Za-z]{1,1}[0-9]{6}$"); //First digit is alpha, then there are exactly 6 numbers
				if (!regexLetter2.test(element.sid)) {
					data[index].barcodeInvalid = 'invalid';
					data[index].errBarcode = 'Please enter a valid barcode';
				}
			}

			if (element.opsTitle === '') {
				data[index].descriptionInvalid = 'invalid';
				data[index].errDescription = 'Please enter the item description';
			}

			// CP 26-Mar-25 - Start

			if (element.typeImage === '') {
				data[index].imageInvalid = 'invalid';
				data[index].errImage = 'Please upload an image for the item';
			}

			// Check to see if the first alpha part of the barcode is a mapped one
			const barcodePrefixCheck = prods.filter(pro => pro.trCode.includes(data[index].sid.substring(0, 1)));
			if (barcodePrefixCheck.length === 0) {

				data[index].barcodeInvalid = 'invalid';
				data[index].errBarcode = 'Please enter a valid barcode';
			}
			// CP 26-Mar-23 - End
		}
		// CP 26-Mar-24 - End

		// Check if flags set
		if (data.filter(x => x.descriptionInvalid !== '' || x.barcodeInvalid !== '' || x.imageInvalid !== '').length === 0) {
			// No, update the form and navigate to confirmation page
			setFormFields(data);
			dispatch(setItems([...data]))

			// Goto confirmation page
			navigate('/collectionDate');
		} else {
			// Yes errors, update form data
			setFormFields(data);
		}
	}

	const handleChange = (e, index) => {
		// This is called when IMAGES are added or removed

		const file = e.target.files[0];
		let data = [...formFields];

		let value = e.target.value;

		// setPicture(URL.createObjectURL(file));

		// // Convert blob to base64 string
		// var reader = new FileReader();
		// reader.readAsDataURL(file);
		// reader.onloadend = function() {
		//   var base64data = reader.result;
		// }

		// CP 26-Mar-24 - Start
		for (let index = 0; index < data.length; index++) {
			const element = data[index];
			if (element.sid === '') {
				data[index].barcodeInvalid = 'invalid';
				data[index].errBarcode = 'Please enter full 7 digit barcode';
			} else if (element.sid.length !== 7) {
				if (element.sid.length !== 8) {
					data[index].barcodeInvalid = 'invalid';
					data[index].errBarcode = 'Please enter full 7 digit barcode';
				}
			} else {
				var regexLetter2 = new RegExp("^[A-Za-z]{1,1}[0-9]{6}$"); //First digit alphabetic, then there are exactly 6 numbers
				if (!regexLetter2.test(element.sid)) {
					data[index].barcodeInvalid = 'invalid';
					data[index].errBarcode = 'Please enter correct barcode';
				}
				else {
					data[index].barcodeInvalid = "";
					data[index].errBarcode = "";
				}
			}
			// CP 26-Mar-24 - End

			if (element.opsTitle === '') {
				data[index].descriptionInvalid = 'invalid';
				data[index].errDescription = 'Please enter the item description';
			}
			else {
				data[index].descriptionInvalid = "";
				data[index].errDescription = "";

				data[index].imageInvalid = "";
				data[index].errImage = "";
			}
		}

		// If no issues, save the item to the order
		if (data.filter(x => x.descriptionInvalid !== '' || x.barcodeInvalid !== '' || x.imageInvalid !== '').length === 0) {
			data[photoIndex].typeImage = URL.createObjectURL(file);

			if (valetOrder !== '') {
				var adminImage = new FormData();
				adminImage.append("files", file);

				// This is a call to THIS site and hits the ITEMController.cs
				var config = {
					method: 'post',
					maxBodyLength: Infinity,
					url: 'item/uploadAdminImage',
					data: adminImage,
					headers: {
						'Content-Type': 'multipart/form-data; charset=utf-8; boundary="another cool boundary";'
					}
				};

				// Request Log
				addLogger(config, userDetail.email, authToken, "Request");

				axios(config).then(function (response) {
					// Response Log
					addLogger(response, userDetail.email, authToken, "Response");

					if (response.status === 200) {
						const imageurl = response.data.url;
						const itemId = data[photoIndex].id;

						if (itemId) {

							updateImage(imageurl, itemId);

						} else {

							// CP 08-Apr-24 - Start
							// We now need to pass in the mapped product code
							const mappedProds = prods.filter(pro => pro.trCode.includes(data[index].sid.substring(0, 1)));
							if (mappedProds.length !== 0) {

								// Found it, so save item
								var sProd = mappedProds[0].sto;

								var itemContent = JSON.stringify({
									"sid": data[photoIndex].sid,
									"valetOrderId": valetOrder.id,
									"ownerId": userDetail.id,
									"opsTitle": data[photoIndex].opsTitle,
									"itemCode": sProd,
									"orderSid": valetOrder.sid
								});
							}
							// CP 08-Apr-24 - End

							//////////////////////////////////////////////////////////////////////
							// SAVE THE ITEM TO THE ORDER - Uses ItemsController
							//////////////////////////////////////////////////////////////////////

							var configitemContent = {
								method: 'post',
								maxBodyLength: Infinity,
								url: 'item/saveItem',
								headers: {
									'Content-Type': 'application/json'
								},
								data: itemContent
							};

							// Request Log
							// addLogger(configitemContent, userDetail.email, authToken, "Request2");

							axios(configitemContent).then(function (response) {

								// Response Log
								// addLogger(response, userDetail.email, authToken, "Response2");

								if (response.status === 200) {

									data[photoIndex].id = response.data.id;
									updateImage(imageurl, response.data.id);

								} else {
									errorMessage();
								}
							})
								.catch(function (error) {
									// Exception Log
									addLogger(error, userDetail.email, authToken, "Exception");
									errorMessage();
								});

						}

						setFormFields(data);

					}
				})
					.catch(function (error) {
						// Exception Log
						addLogger(error, userDetail.email, authToken, "Exception");
						errorMessage();
					});
			}
		} else {
			setFormFields(data);
		}

	};

	const updateImage = (imageUrl, itemId) => {
		let data = [...formFields];

		var config = {
			method: 'POST',
			data: { "url": imageUrl, "itemId": itemId },
			maxBodyLength: Infinity,
			url: `item/updateItemImage`
		};

		// Request Log
		// addLogger(config, userDetail.email, authToken, "Request3");

		axios(config).then(function (response) {

			// Response Log
			// addLogger(response, userDetail.email, authToken, "Response3");

			if (response.status === 200) {
				data[photoIndex].typeImage = response.data.url;
				setFormFields(data);
			} else {
				errorMessage();
			}
		})
			.catch(function (error) {
				// Exception Log
				addLogger(error, userDetail.email, authToken, "Exception");
				errorMessage();
			});

		setFormFields(data);
	}

	const handleClick = (event, index) => {
		setPhotoIndex(index);
		hiddenFileInput.current.click();
	};


	/// ///////////////////////////////////////////////////////////////////////
	///
	/// Function Name   : removeItemFromList
	///
	/// <summary>       : Run when delete barcode clicked and CONFIRMED.
	///
	/// </summary>
	///
	/// Author           : Chris Porter
	///
	/// Revision History :
	/// ----------------------------------------------------------------------
	/// Author     Date      CR Ref #     Description
	/// ----------------------------------------------------------------------
	/// RS         Mar-23                 Created.
	///
	const removeItemFromList = (event, index) => {
		let array = [...formFields];

		const item = array[index];

		if (item.id !== "") {

			var config = {
				method: 'delete',
				maxBodyLength: Infinity,
				url: "item/deleteItem?itemId=" + item.id
			};

			// Request Log
			addLogger(config, userDetail.email, authToken, "Request");

			axios(config).then(function (response) {

				// Response Log
				addLogger(response, userDetail.email, authToken, "Response");

				if (response.status === 200) {
					if (index > -1) { // only splice array when item is found
						array.splice(index, 1); // 2nd parameter means remove one item only
					}
					setFormFields(array);
				} else {
					errorMessage();
				}
			})
				.catch(function (error) {
					// Exception Log
					addLogger(error, userDetail.email, authToken, "Exception");
					errorMessage();
				});
		} else {
			if (index > -1) { // only splice array when item is found
				array.splice(index, 1); // 2nd parameter means remove one item only
			}
			setFormFields(array);
		}

	}

	const removeItem = (event, index) => {
		confirmAlert({
			title: 'Confirm to remove',
			message: 'Are you sure to do this.',
			buttons: [
				{
					label: 'Confirm',
					className: 'btn btn-danger popup-confirm-button',
					onClick: () => {
						removeItemFromList(event, index);
					}
				},
				{
					label: 'Cancel',
					className: 'btn btn-primary cancel-button',
					// onClick: () => alert('Click No')
				}
			],
			closeOnClickOutside: false, // this makes it modal 
		});
	};

	return (

		<div>
			<div class="container">

				{/* 2 stage conversion, figma to bootstrap, bootstrap to react, then manually update css as doesnt convert  */}
				<div className="row mt-0">

					<div className="col-md-6">
						<h2 className="text-muted">It's time to <span className="text-primary">check-in</span></h2>
						<p className="text-muted">Please enter your Spaceways boxes details below:</p>
					</div>

					<div className="d-flex align-items-center mb-3">
						<div style={{ paddingRight: '8px' }}>
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 64 64">
								<circle cx="32" cy="32" r="30" fill="#C227B9" />
								<path fill="#FFFFFF" d="M27.012 47.721L13.818 34.542 16.232 32.127 27.013 42.925 47.464 22.476 49.879 24.891 27.012 47.721z" />
							</svg>
						</div>
						<div className="text-muted fw-600">Helps you stay organised</div>
					</div>

					<div className="d-flex align-items-center mb-3">
						<div style={{ paddingRight: '8px' }}>
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 64 64">
								<circle cx="32" cy="32" r="30" fill="#C227B9" />
								<path fill="#FFFFFF" d="M27.012 47.721L13.818 34.542 16.232 32.127 27.013 42.925 47.464 22.476 49.879 24.891 27.012 47.721z" />
							</svg>
						</div>
						<div className="text-muted fw-600">Easily add photos of all your items</div>
					</div>

					<div className="d-flex align-items-center">
						<div style={{ paddingRight: '8px' }}>
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 64 64">
								<circle cx="32" cy="32" r="30" fill="#C227B9" />
								<path fill="#FFFFFF" d="M27.012 47.721L13.818 34.542 16.232 32.127 27.013 42.925 47.464 22.476 49.879 24.891 27.012 47.721z" />
							</svg>
						</div>
						<div className="text-muted fw-600">Create a record of everything you are storing</div>
					</div>
				</div>


				<div className='collections-box'>
					<div class="text-primary">Your box details</div>
					<form onSubmit={addMore}>
						{
							formFields.map((form, index) => {
								return (<div className='row mb-1' key={index}>
									<div className='col-md-2 collection-input'>
										<input type='text' pattern="[A-Za-z][0-9]{6}" className={form.barcodeInvalid === '' || form.barcodeInvalid === undefined ? 'form-control' : 'form-control invalid'} name='sid' maxLength='7'
											placeholder='Barcode' onChange={event => addFormHandler(event, index)} value={form.sid} autoComplete='off' />
										<span className='error-text'>{form.errBarcode}</span>
									</div>

									<div className='col-md-7 collection-input'>
										<input type='text' className={form.descriptionInvalid === '' || form.descriptionInvalid === undefined ? 'form-control' : 'form-control invalid'}
											name='opsTitle' maxLength='200' placeholder='Box description' onChange={event => addFormHandler(event, index)} value={form.opsTitle} autoComplete='off' />
										<span className='error-text'>{form.errDescription}</span>
									</div>



									<div className='col-md-3 collection-input'>

										{/* Upload Image is a .png located in \ClientApp\src\images\  */}
										<img src={form.typeImage === '' ? camera : form.typeImage} alt='camera' className='camera-img' onClick={event => handleClick(event, index)} title='Change Image' />
										<input type='file' id='capture' accept='image/*' capture onChange={(event) => handleChange(event, index)} ref={hiddenFileInput} style={{ 'display': 'none' }} />
										<span className='error-text'>{form.errImage}</span>
										<FontAwesomeIcon className='fa-trash' icon={faTrash} onClick={event => removeItem(event, index)} title='Remove Box' />


									</div>
								</div>)
							})
						}
					</form>

					<div className='row mt-2'>
						<div className='col-md-2'>
							<button type='button' className='add-button' onClick={addMore}>ADD BOXES</button>
						</div>

						<div className='col-md-8'>
							{itemAdd && <p style={{ 'color': 'red', 'fontWeight': 'bold' }}>You cannot add more than 50 items</p>}
							<button type='button' className='collection-confirm-button' onClick={submit}>SUBMIT</button>
						</div>

					</div>

					<ToastContainer />
				</div>

				<div class="row mb-4">

				</div>



				<div className="row">
					<div class="col-md-6">
						<h2 class="text-muted">Inventory: <span class="text-primary">How to video</span></h2>
					</div>

					<div class="col-md-6">
						<p class="text-muted">Need help? Follow the how-to video below to understand what to do.</p>
					</div>

				</div>



				<iframe width="1120" height="730" src="https://www.youtube.com/embed/C4t4T71uesc?si=KQ5R9V_iJAqtu6BS"
					title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
			</div>
		</div >
	)
}

export default Collections