import React from 'react';
import './Confirmation.css';

const Confirmation = () => {
  return (
    <div className='container'>
      <div className='confirm-text'>
        Thank you for confirming <br/> your order. You are all set <br/> for collection.
      </div>
    </div>
  )
}

export default Confirmation