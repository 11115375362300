import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';

import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  // <BrowserRouter basename={baseUrl}>
  //   <App />
  // </BrowserRouter>);

  // <React.StrictMode>
  //   <Provider store={store}>
  //     <PersistGate persistor={persistor}>
  //       <BrowserRouter basename={baseUrl}>
  //         <Routes>
  //           <Route path="/*" element={<App />} />
  //         </Routes>
  //       </BrowserRouter>
  //     </PersistGate>
  //   </Provider>
  // </React.StrictMode>

  // Remove <React.StrictMode> because render twice
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter basename={baseUrl}>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </PersistGate>
  </Provider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
