import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { setCredentials, selectCurrentToken, logOut } from '../../redux/slices/authSlice';
import { useDispatch } from 'react-redux';

const RequireAuth = () => {
    const authToken = useSelector(selectCurrentToken)
    const location = useLocation()
    const dispatch = useDispatch()

    if (authToken != null) {
        let expireTime = new Date(localStorage.getItem('tokenExpire'));
        let currentTime = new Date();

        if (expireTime > currentTime) {
            let dt = new Date();
            localStorage.setItem('tokenExpire', new Date(dt.setMinutes(dt.getMinutes() + 15)));
        } else {
            dispatch(setCredentials(logOut()))
        }
    }

    return (        
        authToken
            ? <Outlet />
            : <Navigate to="/" state={{ from: location }} replace />
    )
}
export default RequireAuth